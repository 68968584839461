import React from "react"
import { navigate } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/allSite/seo/seo"

import styled from "styled-components"
import {
  headlineOne,
  standardWrapper,
  headlineThree,
  buttonOne,
} from "../Utilities"
import { Link } from "gatsby"

const ThankYouPage = props => {
  console.log("props.location", props.location)
  return (
    <Layout location={props.location.pathname}>
      <SEO
        title={"Thank You!"}
        description={"Thank You!"}
        metaImg={""}
        location={props.location.pathname}
      />
      <StyledSection className="thanks-message">
        <h1>Thank you!</h1>
        <p>Your form has successfully been submitted!</p>
        <p>Thank you and we will be in touch soon!</p>
        <button
          onClick={() => {
            navigate(-1)
          }}
        >
          Home
        </button>
      </StyledSection>
    </Layout>
  )
}

const StyledSection = styled.section`
  ${standardWrapper};

  h1 {
    ${headlineOne};
    width: 100%;
    text-align: center;
  }

  p {
    ${headlineThree};
    margin: 0;
    width: 100%;
    text-align: center;

    &:last-of-type {
      margin-bottom: 5rem;
    }
  }

  button {
    ${buttonOne};
  }
`

export default ThankYouPage
